<template>
  <v-container>
    <v-row class="d-flex flex-column">
      <v-col>
        <!--        <v-card class="card" color="text">-->
        <!--          <v-row>-->
        <!--            <v-col>-->
        <!--              <img class="fruit__img" :src="fruit" />-->
        <!--              <div class="card__text-wrapper&#45;&#45;left">-->
        <!--                <v-card-title class="card__title card__title&#45;&#45;white mb-3">-->
        <!--                  {{ $t('accountPage.profile') }}-->
        <!--                </v-card-title>-->
        <!--                <v-card-subtitle class="card__subtitle card__text&#45;&#45;white ma-1">-->
        <!--                  {{ $t('accountPage.updateYourName') }}-->
        <!--                </v-card-subtitle>-->
        <!--              </div>-->
        <!--            </v-col>-->
        <!--          </v-row>-->
        <!--        </v-card>-->
        <v-card class="card" color="primary">
          <v-row>
            <v-col
                @click="$emit('menuClick', 'types')"
            >
              <img class="jammers__img" :src="jammers" />
              <div class="card__text-wrapper--left">
                <v-card-title class="card__title card__title--white">
                  Jam Typesc
                </v-card-title>
                <v-card-subtitle class="card__subtitle card__text--white ma-1">
                  Add, edit and delete Jam types
                </v-card-subtitle>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="card" color="secondary">
          <v-row>
            <v-col>
              <div
                  class="card__text-wrapper--right"
                  @click="$emit('menuClick', 'prompts')"
              >
                <v-card-title class="justify-end card__title card__title--white">
                  {{ $t('accountPage.prompts') }}
                </v-card-title>
                <v-card-subtitle class="text-right card__subtitle card__text--white ma-1">
                  {{ $t('accountPage.editPrompts') }}
                </v-card-subtitle>
              </div>
              <img class="phone__img" :src="phone" />
            </v-col>
          </v-row>
        </v-card>
<!--        <v-card class="card" color="secondaryBackground">-->
<!--          <v-row>-->
<!--            <v-col>-->
<!--              <div-->
<!--                  class="card__text-wrapper&#45;&#45;open-book"-->
<!--                  @click="$emit('menuClick', 'layout')"-->
<!--              >-->
<!--                <v-card-title class="justify-end card__title card__title&#45;&#45;white">-->
<!--                  {{$t('accountPage.bookLayout')}}-->
<!--                </v-card-title>-->
<!--                <v-card-subtitle class="text-right card__subtitle card__text&#45;&#45;white ma-1">-->
<!--                  {{ $t('accountPage.fixTypos') }}-->
<!--                </v-card-subtitle>-->
<!--              </div>-->
<!--              <img class="open-book__img" :src="openBook" />-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--        </v-card>-->
        <!--        <v-card class="card" color="primaryAction">-->
        <!--          <v-row>-->
        <!--            <v-col>-->
        <!--              <img class="jambook__img" :src="jambook" />-->
        <!--              <div class="card__text-wrapper&#45;&#45;left">-->
        <!--                <v-card-title class="card__title card__title&#45;&#45;white">-->
        <!--                  {{ $t('accountPage.history') }}-->
        <!--                </v-card-title>-->
        <!--                <v-card-subtitle class="card__subtitle card__text&#45;&#45;white ma-1">-->
        <!--                  {{ $t('accountPage.reviewEditOldBooks') }}-->
        <!--                </v-card-subtitle>-->
        <!--              </div>-->
        <!--            </v-col>-->
        <!--          </v-row>-->
        <!--        </v-card>-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'InfoCards',
  computed: {
    fruit() {
      return require('@/assets/FRUIT.svg')
    },
    phone() {
      return require('@/assets/PHONE.svg')
    },
    jammers() {
      return require('@/assets/JAMMERS.svg')
    },
    jambook() {
      return require('@/assets/JAMBOOKS_BOOK.svg')
    },
    openBook() {
      return require('@/assets/OPEN_BOOK.svg')
    }
  }
}
</script>

<style scoped>
.card {
  border-radius: 20px;
  height: 12rem;
  margin-bottom: 3.75rem;
}
.card__title {
  font-size: 3rem;
  letter-spacing: 0.1em;
  line-height: 115%;
}
.card__subtitle {
  font-size: 1.6rem;
  letter-spacing: 0.1em;
}
.fruit__img {
  position: absolute;
  top: 3rem;
}
.phone__img {
  position: absolute;
  top: 3rem;
  right: 0px;
  margin-right: 1.25rem;
}
.jammers__img {
  position: absolute;
  top: 4rem;
  margin-left: 1.25rem;
}
.open-book__img {
  position: absolute;
  top: 1.25rem;
  right: 0px;
}
.jambook__img {
  position: absolute;
  top: 4rem;
}
.card__text-wrapper--left {
  margin-left: 12rem;
}
.card__text-wrapper--right {
  margin-right: 12rem;
}
.card__text-wrapper--open-book {
  margin-right: 15rem;
}
</style>
